<template>
  <div class="container-fluid">
    <CRow class="pb-3">
      <CCol>
        <h4 class="text-uppercase">Consumption Analytics</h4>
      </CCol>
    </CRow>
    <CCard>
      <form v-on:submit.prevent="filterBill(search)">
        <CRow>
          <CCol cols="2">
            <div class="form-group">
              <select name="status" v-model="search.view" class="form-control">
                <option value>View As</option>
                <option value="1">Weekly Chart</option>
                <option value="2">Daily Chart</option>
              </select>
            </div>
          </CCol>

          <CCol cols="2">
            <div class="form-group">
              <select name="status" v-model="search.month" class="form-control">
                <option value>Select Month</option>
                <option value="01">January</option>
                <option value="02">February</option>
                <option value="03">March</option>
                <option value="4">April</option>
                <option value="05">May</option>
                <option value="06">June</option>
                <option value="07">July</option>
                <option value="08">August</option>
                <option value="09">September</option>
                <option value="10">October</option>
                <option value="11">November</option>
                <option value="12">December</option>
              </select>
            </div>
          </CCol>

          <CCol cols="2">
            <div class="form-group">
              <select name="status" v-model="search.year" class="form-control">
                <option value>Select Year</option>
                <option value="2020">2020</option>
                <option value="2021">2021</option>
                <option value="2022">2022</option>
                <option value="2023">2023</option>
                <option value="2024">2024</option>
                <option value="2025">2025</option>
              </select>
            </div>
          </CCol>

          <CCol cols="2">
            <button type="submit" class="btn btn-success w-100">
              Search
            </button>
          </CCol>

          <!-- <CCol cols="2">
          <div class="form-group">
            <datepicker
              :format="dateFormat"
              placeholder="Select From Date"
              wrapper-class="expire-date-picker-wrapper"
              input-class="expire-date-picker"
              class="form-control"
              v-model="search.from"
              name="expiry_date"
            ></datepicker>
          </div>
        </CCol>
        <CCol cols="2">
          <div class="form-group">
            <datepicker
              :format="dateFormat"
              placeholder="Select To Date"
              wrapper-class="expire-date-picker-wrapper"
              input-class="expire-date-picker"
              class="form-control"
              v-model="search.to"
              name="to_date"
            ></datepicker>
          </div>
        </CCol> -->
        </CRow>
        <!-- <CRow>
        <CCol cols="12">
          <button type="submit" class="btn btn-primary float-right">
            Search
          </button>
        </CCol>
      </CRow> -->
      </form>
      <CRow>
        <CCol sm="6">
          <CChartBar  :datasets="newChartData" :labels="chartLabels" :options="{ scales:{ yAxes: [{ticks: { beginAtZero: true } }] } }"></CChartBar>
        </CCol>

        <CCol sm="6">
          <CChartLine :datasets="newChartData" :labels="chartLabels" />
        </CCol>
      </CRow>
    </CCard>

    <!-- <form v-on:submit.prevent="filterBill(search)">
      <CRow v-if="isMobileOnly">
        <CCol>
          <h3 class="heading-title">Billing Analytics</h3>
        </CCol>
      </CRow>

      <CRow v-if="isMobileOnly">
        <CCol cols="12">
          <div class="form-group">
            <select name="status" v-model="search.view" class="form-control">
              <option value>View As</option>
              <option value="1">Weekly Chart</option>
              <option value="2">Daily Chart</option>
            </select>
          </div>
        </CCol>
      </CRow>
      <CRow v-if="isMobileOnly">
        <CCol cols="12">
          <div class="form-group">
            <select name="status" v-model="search.month" class="form-control">
              <option value>Select Month</option>
              <option value="1">January</option>
              <option value="2">February</option>
              <option value="3">March</option>
              <option value="4">April</option>
              <option value="5">May</option>
              <option value="6">June</option>
              <option value="7">July</option>
              <option value="8">August</option>
              <option value="9">September</option>
              <option value="10">October</option>
              <option value="11">November</option>
              <option value="12">December</option>
            </select>
          </div>
        </CCol>
      </CRow>

      <CRow v-if="isMobileOnly">
        <CCol cols="12">
          <div class="form-group">
            <select name="status" v-model="search.year" class="form-control">
              <option value>Select Year</option>
              <option value="2020">2020</option>
              <option value="2021">2021</option>
              <option value="2022">2022</option>
              <option value="2023">2023</option>
              <option value="2024">2024</option>
              <option value="2025">2025</option>
            </select>
          </div>
        </CCol>
      </CRow>

      <CRow v-if="isMobileOnly">
        <CCol cols="12">
          <button type="submit" class="btn btn-primary download-button-width">
            Search
          </button>
        </CCol>
      </CRow>
    </form> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import { CChartLine } from "@coreui/vue-chartjs";
import { CChartBar } from "@coreui/vue-chartjs";

// import BarChart from "../../../dashboard/MeterConsumptionsReportBarChartComponent";
// import LineChart from "../../../dashboard/MeterConsumptionReportLineChartComponent";
export default {
  name: "BillingAnalytics",
  data: () => {
    return {
      search: {
        month: "",
        year: "",
        view: "1",
      },
      datacollection: null,
      dateFormat: "yyyy-MM-dd",
      isMobile: Boolean,
      isMobileOnly: Boolean,
      bills: [],
      deletingState: false,
      loadingState: false,
      role: {},
      serverErrors: [],
      showDismissibleAlert: false,
      // isLoading: false,
      rows: 1,
      perPage: 10,
      currentPage: 1,
      // weekConsumptions: [],
      labelArray: [],
      chartDataArray: [],
      breadcrumbs: [{text:"Home", path:"/dashboard", isActive: false}, {text:"Reports", path:"/reports", isActive: false}, {text:"Analytics", path:"", isActive: true}]
    };
  },
  components: { CChartLine, CChartBar },
  computed: {
    ...mapGetters("Report", ["newChartData", "chartLabels"]),
  },
  filters: {
    moment: function (date) {
      return moment(date).format("DD MMM, YYYY");
    },
  },
  // watch: {
  //   weekConsumptions: {
  //     handler: function (val) {
  //       this.weekConsumptions = val;
  //     },
  //     deep: true,
  //   },
  // },

  methods: {
    filterBill(search) {
      if (search) {
        // this.billingAnalytics();
        this.$store.dispatch("Report/billingAnalytics", search);
      }
    },
  },
  mounted() {
    // this.getbills();
    this.$store.dispatch("Report/billingAnalytics", this.search);
    // this.$store.dispatch("Report/billingAnalytics",search);
  },
  created() {
    this.$store.dispatch("Config/setBreadCrumb", this.breadcrumbs);
  },
};
</script>

<style scoped>
.card {
    border: none;
    padding: 15px 15px 0;
}
</style>

<style lang="scss" scoped>
div {
  h4 {
    font-size: 15px;
    font-weight: bold !important;
    color: #000;
  }
}
</style>
